import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import Button from "../Button/Button"

interface Props {
  introHeadline?: string
  introDescription?: string
  introDescriptionTwo?: string
  introCtaLink?: {
    url: string
    title: string
    target: string
  }
}

const SectionIntro: React.FC<Props> = ({
  introHeadline,
  introDescription,
  introCtaLink,
  introDescriptionTwo,
}) => {
  const [text, setText] = useState({
    introHeadline,
    introDescription,
    introCtaLink,
    introDescriptionTwo,
  })
  const { language } = useContext(LanguageContext)
  useEffect(() => {
    if (language === "en") {
      setText({
        introHeadline,
        introDescription,
        introCtaLink,
        introDescriptionTwo,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          introHeadline,
          introDescription,
          introCtaLink,
          introDescriptionTwo,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`mt-12 md:px-4`]}>
      <SectionHeading>{text?.introHeadline}</SectionHeading>
      <p
        dangerouslySetInnerHTML={{ __html: text?.introDescription }}
        css={[
          tw`text-base md:text-xl mt-5 max-w-4xl mx-8 md:mx-auto text-center`,
        ]}
      />
      <Button
        styles={tw`mx-auto my-8 flex`}
        link={introCtaLink?.url}
        text={introCtaLink?.title}
        target="_blank"
        rel="noopener noreferrer"
      />
      <p
        dangerouslySetInnerHTML={{ __html: text?.introDescriptionTwo }}
        css={[
          tw`text-base md:text-xl mt-5 max-w-4xl mx-8 md:mx-auto text-center`,
        ]}
      />
    </article>
  )
}

export default SectionIntro

import { getImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { graphql, PageProps } from "gatsby"
import { PropertiesTemplatePageData, TemplateProps } from "../templates"
import PropertiesTitle from "../../components/Properties/PropertiesTitle"
import SectionOne from "../../components/Properties/SectionOne"
import SectionThree from "../../components/Properties/SectionThree"
import PropertiesCards from "../../components/Properties/PropertiesCards"
import { SEO } from "../../components/SEO"
import { H1 } from "../../components/Typography"
import { Image } from "../../components/Image"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import SectionIntro from "../../components/Properties/SectionIntro"

const Properties: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<PropertiesTemplatePageData>) => {
  const { template, title, content, featuredImage } = data.wpPage
  const { sectionIntro, cardsSection, sectionOne, sectionThree } =
    template.propertiesPage

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <div css={[tw`relative`]}>
          <H1
            css={[
              tw`absolute text-white text-4xl font-extrabold sm:text-5xl xl:text-6xl z-20 w-full h-full flex items-center justify-center`,
              "text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);",
            ]}
          >
            {text?.title}
          </H1>

          <Image
            src={getImage(
              featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
            )}
            publicURL={featuredImage?.node?.localFile?.publicURL}
            role="presentation"
            objectPosition="22% 50%"
            css={[tw`h-80`]}
            alt=""
          />
        </div>
        <PropertiesTitle title="" content={content} />
        <SectionIntro {...sectionIntro} />
        <SectionOne {...sectionOne} />
        <PropertiesCards {...cardsSection} />
        <SectionThree {...sectionThree} />
      </section>
    </div>
  )
}

export default Properties

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_Properties {
          templateName
          propertiesPage {
            cardsSection {
              headline
              cards {
                cardType
                title
                description
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
                downloadFile {
                  localFile {
                    publicURL
                  }
                }
                link {
                  target
                  url
                }
              }
            }
            sectionIntro {
              introHeadline
              introDescription
              introDescriptionTwo
              introCtaLink {
                url
                title
                target
              }
            }
            sectionOne {
              description
              headline
            }
            sectionThree {
              description
              headline
            }
          }
        }
      }
    }
  }
`
